/*===========================
 #5.0 cart-page-style
===========================*/
.cart-area {
  .cart-wrapper {
    box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);

    table {
      @media (max-width: 991px) {
        width: 1022px;
      }
    }

    form {
      @media (max-width: 991px) {
        overflow: scroll;
        overflow-y: hidden;
      }
    }
  }

  .disabled-link {
    background-color: #ccc;
    pointer-events: none;
    cursor: not-allowed;
  }

  .cart-wrap td,
  .cart-wrap th {
    width: 10%;
    border-bottom: 1px solid #f0f0f094;
    text-align: center;
    font-weight: 400;
    color: #414141;
  }
  .cart-wrap th {
    border-bottom: 1px solid #f0f0f094;
    padding: 40px 0;
  }

  .cart-wrap thead {
    background: #fff;
    color: #505050;
    padding: 40px;
  }
  .cart-area form {
    margin: auto;
    text-align: center;
    background: #fff;
  }
  .cart-wrap .product {
    width: 15%;
  }
  .cart-wrap .product ul {
    text-align: left;
    padding-left: 30px;
  }
  .cart-wrap .product ul li {
    padding-bottom: 5px;
  }
  .cart-wrap .product a {
    font-size: 16px;
    color: #a5a4a4;
  }

  .cart-wrap .product-2 {
    text-align: left;
    padding-left: 55px;
  }

  .cart-wrap .product a:hover {
    color: $theme-primary-color;
  }

  .cart-wrap th {
    height: 80px;
    font-weight: 700;
  }
  .cart-wrap td {
    padding: 40px 25px;

    &.action {
      ul {
        display: flex;
        justify-content: center;

        .w-btn-view {
          a {
            line-height: 45px;
          }
        }

        .w-btn {
          .flaticon-delete {
            &:before {
              font-size: 30px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .cart-wrap td span {
    display: block;
    width: 100px;
    height: 38px;
    line-height: 34px;
    color: #ee9902;
    font-size: 14px;
    border: 1.5px solid #ee9902;
    border-radius: 4px;
    margin: 0 auto;
  }
  .cart-wrap td.Del span {
    color: #4aba4e;
    border: 1.5px solid #4aba4e;
  }
  .cart-wrap td.can span {
    color: #d85656;
    border: 1.5px solid #d85656;
  }
  .cart-wrap td.pro span {
    color: #691a5f;
    border: 1.5px solid #691a5f;
  }

  .cart-wrap .name {
    width: 15%;
  }
  .cart-wrap .action a,
  .cart-wrap .action button {
    display: block;
    width: 40px;
    height: 38px;
    line-height: 40px;
    background: #414141;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    border: none;

    &.c-btn {
      background: rgb(207, 55, 55);
    }

    .fi {
      &::before {
        font-size: 20px;
      }
    }
  }
  .pro-single-btn {
    max-width: 110px;
  }
  .cart-wrap .action {
    .w-btn {
      display: block;
      width: 40px;
      height: 38px;
      line-height: 45px;
      background: #414141;
      color: #fff;
      border-radius: 4px;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        background: $theme-primary-color;
      }

      i {
        &:before {
          font-size: 20px;
        }
      }
    }
  }

  .cart-wrap {
    .images {
      img {
        max-width: 100px;
      }
    }
  }
  .cart-wrap .action a:hover {
    background: $theme-primary-color;
  }
  .cart-wrap .action li.c-btn {
    margin-right: 10px;
    a {
      background-color: $theme-primary-color;
    }
  }
  .order-wrap {
    padding: 0;
  }
  .cart-wrap tr:nth-child(even) {
    background: #fcfcfc;
  }

  .cart-wrap .quantity {
    position: relative;
  }
  .quantity input {
    width: 105px;
    padding: 0px 35px;
    text-align: center;
    height: 30px;
    position: relative;
    background: #fff;
    border: none;
    border: 1px solid #b1b1b2;
  }
  .quantity .qtybutton {
    position: absolute;
    top: 0;
    left: 0px;
    height: 30px;
    width: 35px;
    text-align: center;
    line-height: 28px;
    cursor: pointer;
    color: #333;
    border-left: 1px solid #b1b1b2;
    min-width: 35px;
    border-radius: 0;
  }
  .cart-wrap .quantity .qtybutton {
    top: 50%;
    left: 0;
    transform: translateY(-51%);
    -webkit-transform: translateY(-51%);
    -moz-transform: translateY(-51%);
    border-right: 1px solid #b1b1b2;
  }
  .cart-wrap .quantity .qtybutton.dec {
    border-left: none;
    z-index: 999;
  }
  .cart-wrap .quantity {
    span {
      border: 0;
      padding: 0;
      width: unset;
      height: unset;
      line-height: unset;
      border-radius: 0;
      color: #333;
    }
  }
  .cart-wrap .quantity .qtybutton.inc {
    right: 0;
    left: auto;
    border-right: none;
  }

  .submit-btn-area {
    padding: 40px;

    ul {
      display: flex;
      justify-content: flex-end;

      @media (max-width: 450px) {
        flex-wrap: wrap;
        justify-content: center;
      }

      li {
        &:last-child {
          margin-left: 20px;

          @media (max-width: 450px) {
            margin-left: 3px;
            margin-top: 5px;
          }
        }
      }
    }

    button {
      padding: 15px 40px;
      border: none;
      background: $small-black;
      color: $white;
      transition: all 0.3s;
      outline: none;
      border-radius: 0px;

      &:hover {
        background: $theme-primary-color;
      }
    }
    .theme-btn {
      text-transform: capitalize;
      font-size: 15px;
      font-weight: 500;
      line-height: 27px;
    }
  }

  .cart-product-list {
    ul {
      border-top: 1px solid #f0f0f094;
      padding-top: 20px;

      li {
        display: flex;
        justify-content: space-between;
        padding: 0px 30px;
        font-weight: 300;
        font-size: 18px;
        line-height: 21px;
        color: #414141;
        padding-bottom: 30px;

        &.cart-b {
          border-top: 1px solid #f0f0f094;
          border-bottom: 1px solid #f0f0f094;
          color: $theme-primary-color;
          padding-top: 30px;
          font-weight: 600;
        }
      }
    }
  }
}
